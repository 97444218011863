import React from "react";
import {
  SEO,
  PageLayout,
  HeroElement,
  Content,
  MenuFilesDisplay,
  Contact,
} from "@bluefin/components";
import { Grid, Header } from "semantic-ui-react";
import { graphql } from "gatsby";

import WebsiteLayout from "../components/WebsiteLayout.jsx";
import { getPageHeroImage } from "../utils/utils";

export default class MeadPage extends React.PureComponent {
  render() {
    const { data } = this.props;
    const { fishermanBusiness, fishermanBusinessWebsitePage } = data;

    return (
      <WebsiteLayout>
        <SEO
          businessName={fishermanBusiness.name}
          pageName={fishermanBusinessWebsitePage.title}
          images={getPageHeroImage({
            options: fishermanBusinessWebsitePage.components,
          })}
        />
        <PageLayout
          hero={
            <HeroElement
              header={"Mead"}
              tagline={""}
              ctas={[
                {
                  text: "Order Online",
                  link: "https://order.online/store/zip's-drive-in-mead-27563387/?hideModal=true&pickup=true",
                  internal: false,
                },
              ]}
              images={[
                "https://fisherman.gumlet.io/public/zipsdriveincheney/Zips_FriedChickenSandwich_Native-min_1.jpg.jpeg",
              ]}
              ctaInverted={true}
              ctaColor={"white"}
              containerColor={"primary"}
              textColor={"white"}
              showMultiple={true}
              overlay={true}
              fullWidth={true}
              parallax={false}
              containerAs={"none"}
              height={500}
            />
          }
        >
          <Grid
            stackable={true}
            className={"component-section-container"}
            verticalAlign={"middle"}
            textAlign={"left"}
          >
            <Grid.Column width={16}>
              <Content container={true}>
                <Content.Markup isMarkdown={false}>
                  <Header as={"h1"} content={"Welcome to Mead"} />
                  <p>
                    Zip’s Drive In has got the best food in town. Come try it
                    out!
                  </p>
                </Content.Markup>
              </Content>
            </Grid.Column>
            <Grid.Column width={16}>
              <MenuFilesDisplay
                files={[
                  {
                    url: "https://fisherman.gumlet.io/public/zipsdriveincheney/Zips_Application.pdf",
                    filename: "Application for Employment",
                  },
                ]}
              />
            </Grid.Column>
            <Grid.Column width={16}>
              <Contact
                businessName={fishermanBusiness.name}
                phoneNumber={fishermanBusiness.locations[2].phoneNumber}
                email={fishermanBusiness.locations[2].email}
                hours={fishermanBusiness.locations[2].hours}
                address={{
                  street: fishermanBusiness.locations[2].street,
                  city: fishermanBusiness.locations[2].city,
                  state: fishermanBusiness.locations[2].state,
                  zip: fishermanBusiness.locations[2].zipCode,
                }}
                as={"text"}
                buttonColor={"secondary"}
                header={"Contact"}
                image={""}
                showMap={true}
              />
            </Grid.Column>
          </Grid>
        </PageLayout>
      </WebsiteLayout>
    );
  }
}

export const query = graphql`
  query {
    fishermanBusiness {
      name
      locations {
        phoneNumber
        email
        hours {
          open
          close
          label
          day
        }
        street
        city
        state
        zipCode
      }
    }
    fishermanBusinessWebsitePage(title: { eq: "Mead" }) {
      title
      components {
        fastId
        order
        files {
          file
        }
      }
    }
  }
`;
